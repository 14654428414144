import MahsupList from './MahsupList';
import MahsupCreate from './MahsupCreate';
import MahsupEdit from './MahsupEdit';


const resource = {
    list: MahsupList,
    create: MahsupCreate,
    edit: MahsupEdit,
};

export default resource;
