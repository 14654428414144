import * as React from 'react';
import {
    Edit,
    TextInput,
    required,
    SimpleForm,
    ArrayInput, 
    SimpleFormIterator, 
    NumberInput,
    useTranslate
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

 
const useStyles = makeStyles({
    
});

const MySimpleFormIterator = props => {
    const classes = useStyles();
    return <SimpleFormIterator className={classes.form} {...props} />;
};

export const styles = {
    accountCode: { display: 'inline-block' },
    accountType: { display: 'inline-block', marginLeft: 32 },
};

const MahsupEdit = (props) => {
 
    return (
        <Edit title={'Mahsup Edit'} {...props} mutationMode="pessimistic">
            <SimpleForm
                // Here for the GQL provider
                // initialValues={{
                //     "user": "",
                //     "role": "",
                // }}
            >
                <SectionTitle label="Edit Mahsup" />
                <TextInput source="note" label="Aciklama"/>
                <ArrayInput source="entries" label="Bilgiler">
                    <MySimpleFormIterator>
                         
                        <TextInput
                            autoFocus
                            label=""
                            source="account"
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                            validate={requiredValidate}
                        />
                         
                        <TextInput
                            autoFocus
                            label="Aciklama"
                            source="entriesNote"
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                        />
                        <NumberInput label="Alacak" source="credit" style={{ display: 'inline', float: 'left', marginLeft: '20px' }}/>
                        <NumberInput label="Borc" source="debit" style={{ display: 'inline', float: 'left', marginLeft: '20px' }}/>
                      
                    </MySimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};

const requiredValidate = [required()];
const SectionTitle = ({ label }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const Separator = () => <Box pt="1em" />;

export default MahsupEdit;
