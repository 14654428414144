import * as React from 'react';
import { useState } from 'react';
import { DashboardMenuItem, Menu as ReactMenu, MenuItemLink } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
 
import SubMenu from './SubMenu';

const Menu = (props) => {
    const [state, setState] = useState({
        menuSales: true,
        menuCustomers: true,
    });
    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    return (
        <ReactMenu {...props}>
            <DashboardMenuItem />
            <SubMenu
                    handleToggle={() => handleToggle('menuSales')}
                    isOpen={state.menuSales}
                    name="İşlemler"
                    icon={<ReceiptIcon />}        
            >
                <MenuItemLink  exact to="/mahsup"   primaryText="Mahsup" leftIcon={<BookIcon />}/>
                <MenuItemLink  exact to="/gelirfaturası" primaryText="Gelir Faturası" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/gelirfişi" primaryText="Gelir Fişi" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/giderfaturası" primaryText="Gider Faturası" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/giderfişi" primaryText="Gider Fişi" leftIcon={<ChatBubbleIcon />}/>
            </SubMenu>
            <SubMenu
                    handleToggle={() => handleToggle('menuCustomers')}
                    isOpen={state.menuCustomers}
                    name="Hesaplar"
                    icon={<PeopleIcon />}        
            >
                <MenuItemLink  exact to="/müşteriler" primaryText="Müşteriler" leftIcon={<BookIcon />}/>
                <MenuItemLink  exact to="/tedarikçiler" primaryText="Tedarikçiler" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/kasalar" primaryText="Kasalar" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/calışanlar" primaryText="Çalışanlar" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/bankalar" primaryText="Bankalar" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/ürünler" primaryText="Ürünler" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/hizmetler" primaryText="Hizmetler" leftIcon={<ChatBubbleIcon />}/>
                <MenuItemLink  exact to="/sermaye" primaryText="Sermaye" leftIcon={<ChatBubbleIcon />}/>
            </SubMenu>
        </ReactMenu>
    )
};

export default Menu;