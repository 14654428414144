import { Admin, Resource } from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import Dashboard from './components/Dashboard';
import NotFound from './components/404NotFound';
import { theme } from './themes/theme';
import Layout from './components/MyLayout';
import CustomRoutes from './components/CustomRoutes';
import Mahsup from './components/Mahsup';

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'tr') {
      return import('./i18n/tr').then(messages => messages.default);
  }

  return englishMessages;
}, 'en');

function App() {
  return <Admin 
          title="Muhasebe Program"
          dataProvider={restProvider('http://localhost:5000/api/muhasebe')}
          dashboard={Dashboard}
          disableTelemetry
          catchAll={NotFound}
          
          layout={Layout}
          customRoutes={CustomRoutes}
          i18nProvider={i18nProvider}
        > 
         
          <Resource name="mahsup" {...Mahsup} />
        
        </Admin>
}

export default App;
