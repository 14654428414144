import * as React from "react";
import { List, Datagrid, TextField, FunctionField } from 'react-admin';

export const MahsupList = (props) => {
    const PostPanel = ({ id, record, resource }) => (
        <div>{record.entries[0].debit}</div>
    );

    return (
        <List {...props} >
            <Datagrid rowClick='edit'  >
                <FunctionField label="Tip"  render={record => `Mahsup Fisi`} />
                {/* <FunctionField label="Not"  render={record => `${record.note}`} /> */}
                <TextField source="note" label="Aciklama"/>
                
            </Datagrid>
        </List>
    )
};

export default MahsupList;