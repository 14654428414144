import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    required,
    ArrayInput, 
    SimpleFormIterator, 
    NumberInput 
} from 'react-admin';
 
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
 

export const styles = {
    accountCode: { display: 'inline-block' },
    accountType: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const MahsupCreate = (props) => {
    const classes = useStyles(props);
 
    const transform = data => ({
        ...data,
        currency: "₺", 
        transactionType:"transaction", 
        isDeleted: false,
    });

    return (
        <Create {...props} transform={transform}>
            <SimpleForm
                // Here for the GQL provider
                // initialValues={{
                //     "user": "",
                //     "role": "",
                // }}
            >
                <SectionTitle label="Create Mahsup" />
                {/* <SectionTitle label="Create Mahsup" />
                
                <Separator />
                <TextInput
                    label="Account Type"
                    source="accountType"
                    formClassName={classes.first_name}
                    validate={requiredValidate}
                /> */}
                <TextInput source="note" label="Aciklama"/>
                <ArrayInput source="entries" label="Bilgiler">
                    <SimpleFormIterator>
                        <TextInput
                            autoFocus
                            label="Hesap"
                            source="account"
                            formClassName={classes.hesap}
                            validate={requiredValidate}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                        />
                         
                        <TextInput
                            autoFocus
                            label="Aciklama"
                            source="entriesNote"
                            formClassName={classes.note}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                        />
                        <NumberInput label="Alacak" source="credit" style={{ display: 'inline', float: 'left', marginLeft: '20px' }}/>
                        <NumberInput label="Borc" source="debit" style={{ display: 'inline', float: 'left', marginLeft: '20px' }}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default MahsupCreate;
